<template>
  <div class="coupons-list">
      <breadcrumb :breadcrumbList="breadcrumbFamily"></breadcrumb>
      <div class="main-content">
        <el-form ref="ruleFormRef" label-position="left" :model="ruleForm"
        class="demo-ruleForm" label-suffix="：" style="display: flex;margin-bottom: 20px;flex-wrap: wrap;align-items: center;">
            <el-form-item prop="keyword" label="关键词">
                <el-input v-model="ruleForm.keyword" placeholder="输入关键词" style="width: 200px;margin-right: 20px;"></el-input>
            </el-form-item>
            <el-form-item prop="bindDate" label="授权时间" style="margin-right: 20px;">
                <el-date-picker
                  @change="handleDateChange"
                  v-model="ruleForm.bindDate"
                  type="daterange"
                  range-separator="到"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  value-format="YYYY-MM-DD"
                />
            </el-form-item>
            <el-form-item prop="unbindDate" label="解绑时间" style="margin-right: 20px;">
                <el-date-picker
                  @change="handleDateChange1"
                  v-model="ruleForm.unbindDate"
                  type="daterange"
                  range-separator="到"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  value-format="YYYY-MM-DD"
                />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search">筛选</el-button>
              <el-button @click="reset">重置</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList" style="width: 100%;margin-bottom: 20px;">
          <el-table-column align="center" type="index" label="序号" />
          <el-table-column align="center" prop="bindUser" label="办卡人/手机号">
            <template #default="{row}">
            {{ row.bindUser }}（{{ row.bindUserPhone }}）
            </template>
          </el-table-column>
          <el-table-column align="center" prop="groupMasterUser" label="授权人/手机号">
            <template #default="{row}">
            {{ row.groupMasterUser }}（{{ row.groupMasterUserPhone }}）
            </template>
          </el-table-column>
          <el-table-column align="center" prop="bindTime" label="授权时间" />
          <el-table-column align="center" prop="unBindTime" label="解绑时间" />
        </el-table>
        <el-pagination v-model:currentPage="queryInfo.page" v-model:page-size="queryInfo.pagesize"
        :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="dataCount"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted ,watch,computed} from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { useRouter } from 'vue-router'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'
import { getFamilyAccountBindRecordList } from '@/service/main/marketing'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbFamily } from '../config'

export default defineComponent({
  props: {
    activeName: {
      type: Number,
      default: 0
    },
  },
  components: {
    PageContent,
    Breadcrumb
  },
  setup(props) {
    const router = useRouter()
    const queryInfo = reactive({
      page: 1,
      pagesize: 10
    })
    const ruleForm = reactive({
      keyword:null,
      bindDate:null,
      unbindDate:null
    })
    const dataCount = ref(0)
    const keyword = ref(null)
    const dataList = ref([])
    const handleDateChange = (e)=>{
      if (e) {
        ruleForm.bindStartDate=e[0]
        ruleForm.bindEndDate=e[1]
      }else{
        ruleForm.bindStartDate=undefined
        ruleForm.bindEndDate=undefined
      }
    }
    const handleDateChange1 = (e)=>{
      if (e) {
        ruleForm.unbindStartDate=e[0]
        ruleForm.unbindEndDate=e[1]
      }else{
        ruleForm.unbindStartDate=undefined
        ruleForm.unbindEndDate=undefined
      }
    }
    const search = ()=>{
      queryInfo.keyword = ruleForm.keyword
      queryInfo.bindStartDate = ruleForm.bindStartDate
      queryInfo.bindEndDate = ruleForm.bindEndDate
      queryInfo.unbindStartDate = ruleForm.unbindStartDate
      queryInfo.unbindEndDate = ruleForm.unbindEndDate
      initPage()
    }
    const reset = ()=>{
      queryInfo.keyword = null
      queryInfo.bindStartDate = null
      queryInfo.bindEndDate = null
      queryInfo.unbindStartDate = null
      queryInfo.unbindEndDate = null
      ruleForm.keyword=null,
      ruleForm.bindDate=null,
      ruleForm.unbindDate=null
      initPage()
    }
    const handleCurrentChange = page => {
      queryInfo.page = page
      initPage()
    }
    const handleSizeChange = (pageSize) => {
      queryInfo.pagesize = pageSize
      initPage()
    }
    const initPage = async () => {
      let {page,pagesize,keyword,bindStartDate,bindEndDate,unbindStartDate,unbindEndDate} = queryInfo;
      let params={
        current:page,
        size:pagesize,
        keyword,
        bindStartDate,
        bindEndDate,
        unbindStartDate,
        unbindEndDate,
      }
      const res = await getFamilyAccountBindRecordList(params)
      let list = res.data.list
      dataList.value = list
      dataCount.value=Number(res.data.total)
    }
    initPage()

    return {
      queryInfo,
      dataCount,
      dataList,
      keyword,
      search,
      reset,
      handleCurrentChange,
      handleSizeChange,
      ruleForm,
      handleDateChange,
      handleDateChange1,
      breadcrumbFamily
    }

  }
})
</script>

<style scoped lang="less">
.coupons-list {
  .main-content {
    padding: 20px 80px 20px 20px;
    margin: 20px;
    background-color: #fff;
    .title{
      padding-top: 10px;
      margin-bottom: 40px;
    }
  }
  // margin-top: 20px;
  .line {
    display: inline-block;
    margin: 0 6px;
  }
  .border{
    color: #ccc;
    padding: 0 6px;
  }
  .example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary) !important;
    display: flex;
    align-items: center;
  }
  &:deep(.el-table) {


    .el-table__header-wrapper {
      
      .el-table__cell {

        background-color: rgba(239, 239, 239, 1);

        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }

    }
  }
}
</style>
