import { request4 } from '@/service/request4'

//获取邀请活动列表
export function getInvitingActivityList(data) {
  return request4({
    url: '/invitingActivity/web/getInvitingActivityList',
    method: 'post',
    data
  })
}
//创建邀请活动
export function createInvitingActivity(data) {
  return request4({
    url: '/invitingActivity/web/createInvitingActivity',
    method: 'post',
    data
  })
}
//编辑邀请活动
export function editInvitingActivity(data) {
  return request4({
    url: '/invitingActivity/web/editInvitingActivity',
    method: 'put',
    data
  })
}
//获取邀请活动
export function getInvitingActivity(id) {
  return request4({
    url: `/invitingActivity/web/getInvitingActivity?id=${id}`,
    method: 'get'
  })
}
//启用/禁用邀请活动
export function enabledInvitingActivity(id) {
  return request4({
    url: `/invitingActivity/web/enabledInvitingActivity?id=${id}`,
    method: 'get'
  })
}
//删除活动
export function removeInvitingActivity(id) {
  return request4({
    url: `/invitingActivity/web/enabledInvitingActivity?id=${id}`,
    method: 'get'
  })
}

//获取邀请记录
export function getInvitingRecord(data) {
  return request4({
    url: '/invitingActivity/web/getInvitingRecord',
    method: 'post',
    data
  })
}
//获取家庭账户绑定记录
export function getFamilyAccountBindRecordList(data) {
  return request4({
    url: '/familyAccountGroup/web/getFamilyAccountBindRecordList',
    method: 'post',
    data
  })
}
//获取邀请二维码
export function getInvitingEqCode(id) {
  return request4({
    url: `/invitingActivity/web/getInvitingEqCode?id=${id}`,
    method: 'get'
  })
}